import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useCredit } from "Components/Layout/Navbar/Navbar.hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import DropdownListItem from "Components/Common/Dropdown/DropdownListItem";
import Skeleton from "react-loading-skeleton";
import NumberView from "Components/Common/NumberView";

function CreditsItemForSaas() {
  // Destructing `t` function for translation.
  const { t } = useTranslation();

  const { credit, fetchingCredit, fetchCredit } = useCredit();
  const { pathname } = useLocation();

  const nav = useNavigate();

  useEffect(() => {
    fetchCredit();

    // eslint-disable-next-line
  }, []);

  return (
    <DropdownListItem
      selected={pathname === "/credits"}
      onClick={() => nav("/credits")}
      icon={solid("cedi-sign")}
    >
      <span>
        {fetchingCredit ? (
          <Skeleton />
        ) : (
          <>
            <strong>
              <NumberView value={credit} />
            </strong>{" "}
            {t("credit")}
          </>
        )}
      </span>
    </DropdownListItem>
  );
}

export default CreditsItemForSaas;
