import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAuthContext } from "Context/AuthContextProvider";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DropdownListDirection } from "Components/Common/Dropdown/DropdownList";
import { useFetchUnusedFiles } from "Components/Layout/Navbar/Navbar.hooks";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "Context/UserSettingsProvider";
import { usePlatform } from "Context/PlatformContextProvider";
import { useEffect, useState } from "react";

import Dropdown from "Components/Common/Dropdown";
import DropdownList from "Components/Common/Dropdown/DropdownList";
import DropdownListItem from "Components/Common/Dropdown/DropdownListItem";
import DropdownDivider from "Components/Common/Dropdown/DropdownDivider";
import CreditsItemForSaas from "./Components/CreditsItemForSaas";
import { useTheme } from "Context/ThemeContextProvider";
import { DropdownProvider } from "./Components/DropdownProvider";
import { Storage } from "Utils/Constants";

type ShowTheme = "light" | "dark" | "system";

interface NavbarExtraDropdownAuthenticatedProps {
  handleToggleLanguage: () => void;
}

function NavbarExtraDropdownAuthenticated({
  handleToggleLanguage,
}: NavbarExtraDropdownAuthenticatedProps) {
  // Destructing `t` function for translation.
  const { t } = useTranslation();
  const { isSaaS } = usePlatform();
  const { username, logout } = useAuthContext();
  const { unusedFiles } = useFetchUnusedFiles();
  const { pathname } = useLocation();
  const { changingUserLocale, locale } = useUserSettings();

  // Destructures values and functions from the useTheme() hook, which accesses the ThemeContext
  //to manage and provide the current theme, theme toggle state, and functions for updating the theme.

  const { isToggled, lightTheme, darkTheme, systemTheme, setIsToggled } =
    useTheme();

  // Set the search params a boolean states for detecting unused files are empty or not.
  const [searchParams] = useSearchParams();

  //// The `setShowTheme` function is used to update the selected theme.

  const [showTheme, setShowTheme] = useState<ShowTheme>(() => {
    const currentTheme = localStorage.getItem(Storage.THEME);
    if (!currentTheme) {
      return "system";
    }
    return currentTheme as ShowTheme;
  });

  const [unusedFilesHasInticator, setUnusedFilesHasInticator] =
    useState<boolean>(true);

  function handleLogout() {
    // Redirect to home when user is logged out.
    nav("/");
    logout();
  }

  function handleShowLightTheme() {
    lightTheme();
    setShowTheme("light");
  }
  function handleShowDarkTheme() {
    darkTheme();
    setShowTheme("dark");
  }
  function handleShowSystemTheme() {
    systemTheme();
    setShowTheme("system");
  }

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const nav = useNavigate();

  useEffect(
    () =>
      setUnusedFilesHasInticator(
        searchParams.get("empty-unused-files") === "true"
      ),

    [searchParams]
  );

  function handleToggleDropdown() {
    if (isToggled) {
      setIsToggled(false);
    }
  }

  return (
    <Dropdown
      downIcon
      hasIndicator={
        unusedFiles && unusedFiles.length > 0 && !unusedFilesHasInticator
      }
      buttonProps={{
        title: t("show-user-settings"),
        children: (
          <>
            {username.length > 10
              ? username.substring(0, 10) + "..."
              : username}
          </>
        ),
        variant: "outlined",
        color: "primary",
        size: "normal",
        iconLeft: regular("user"),
      }}
      onClick={handleToggleDropdown}
    >
      <DropdownList openTo={DropdownListDirection.LEFT}>
        <DropdownListItem
          selected={pathname === "/profile"}
          onClick={() => nav("/profile")}
          icon={solid("user")}
        >
          <span>{t("my-account")}</span>
        </DropdownListItem>

        {unusedFiles && unusedFiles.length > 0 && !unusedFilesHasInticator && (
          <DropdownListItem
            selected={pathname === "/files/unused/"}
            onClick={() => nav("/files/unused/")}
            icon={solid("file")}
            hasInditacor
          >
            <span>{t("unused-files")}</span>
          </DropdownListItem>
        )}

        <DropdownListItem
          onClick={handleToggleLanguage}
          className="d-grid-mobile"
          disabled={changingUserLocale}
          icon={solid("globe")}
        >
          <span style={{ textTransform: "uppercase" }}>{locale}</span>
        </DropdownListItem>

        <DropdownListItem
          onClick={handleToggle}
          icon={solid("brush")}
          endIcon={isToggled ? solid("chevron-up") : solid("chevron-down")}
        >
          {t("themes")}
        </DropdownListItem>

        <DropdownProvider visible={isToggled}>
          <DropdownListItem
            onClick={handleShowLightTheme}
            icon={solid("sun")}
            selected={showTheme === "light"}
          >
            <span>{t("light-mode")}</span>
          </DropdownListItem>

          <DropdownListItem
            onClick={handleShowSystemTheme}
            icon={solid("adjust")}
            selected={showTheme === "system"}
          >
            <span>{t("system-mode")}</span>
          </DropdownListItem>

          <DropdownListItem
            onClick={handleShowDarkTheme}
            icon={solid("moon")}
            selected={showTheme === "dark"}
          >
            <span>{t("dark-mode")}</span>
          </DropdownListItem>
        </DropdownProvider>

        <DropdownListItem
          onClick={handleLogout}
          icon={solid("right-from-bracket")}
        >
          <span>{t("sign-out")}</span>
        </DropdownListItem>

        {isSaaS && (
          <>
            <DropdownDivider />
            <CreditsItemForSaas />
          </>
        )}
      </DropdownList>
    </Dropdown>
  );
}

export default NavbarExtraDropdownAuthenticated;
